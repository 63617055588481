export default class Str {
    /**
     * Format a static tokenized string with values.
     */
    static format(format: string, arg1: any): string
    static format(format: string, arg1: any, arg2: any): string
    static format(format: string, arg1: any, arg2: any, arg3: any): string
    static format(format: string, arg1: any, arg2: any, arg3: any, arg4: any): string
    static format(format: string, arg1: any, arg2?: any, arg3?: any, arg4?: any): string {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        return [...args].reduce((prev: string, curr) => prev.replace(/%s/, curr ?? ''), format).replace(/%s/, '');
    }

}
